import { useOnOff } from '@/hooks/use-on-off';
import { useStateCallback } from '@/hooks/use-state-callback';
import * as React from 'react';

interface ITransactionInfo {
  action: string;
  status: number;
  txID: string;
  title: string;
}

const initTransaction = {
  action: '',
  status: 0,
  txID: '',
  title: ''
};

export interface INotificationContext {
  addNotification: (title: string, action: string, status: number, txID: string) => void;
  isShow: boolean;
  transactionInfo: ITransactionInfo;
  closeNotificaiton: () => void;
}

const NotificationContext = React.createContext<INotificationContext | null>(null);
export const NotificationContextProvider = ({ children }: React.PropsWithChildren) => {
  const { isOn: isShow, turnOn: handleShow, turnOff: handleClose } = useOnOff(false);
  const [transactionInfo, setTransactionInfo] = useStateCallback<ITransactionInfo>(initTransaction);
  const addNotification = React.useCallback(
    (title: string, action: string, status: number, txID: string) => {
      const _info = { ...transactionInfo };
      _info.action = action;
      _info.title = title;
      _info.status = status;
      _info.txID = txID;
      setTransactionInfo(_info, handleShow);
    },
    [transactionInfo, handleShow, setTransactionInfo]
  );

  const closeNotificaiton = React.useCallback(() => {
    setTransactionInfo(initTransaction);
    handleClose();
  }, [setTransactionInfo, handleClose]);
  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        isShow,
        transactionInfo,
        closeNotificaiton
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useNotificationContext = (): INotificationContext => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext Error');
  }
  return context as INotificationContext;
};
