import { useOnOff } from '@/hooks/use-on-off';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useContractProviderContext } from './contractProviderContext';
import { DIAMOND as DIAMONDRepository } from '@/repository/diamond.repository';
import { nonAddress } from '@/constants/app.contanst';
import { useStateCallback } from '@/hooks/use-state-callback';
import { useIntervalContext } from './intervalContext';
import * as Sentry from '@sentry/react';

export interface ITransactionInfo {
  commit: string;
  nonce: string;
  targetId: number;
  type: number;
}

const initTransaction = {
  commit: '',
  nonce: '',
  targetId: -1,
  type: 0
};

export interface IRetryTransactionContext {
  isPending: boolean;
  isShow: boolean;
  handleClose: () => void;
  setUserPetId: (id: number) => void;
  transactionInfo: ITransactionInfo;
  handleShow: () => void;
  refetch: () => void;
}

const RetryTransactionContext = React.createContext<IRetryTransactionContext | null>(null);
export const RetryTransactionContextProvider = ({ children }: React.PropsWithChildren) => {
  const { provider } = useContractProviderContext();
  const { isOn: isShow, turnOn: handleShow, turnOff: handleClose } = useOnOff(false);
  const [transactionInfo, setTransactionInfo] = useStateCallback<ITransactionInfo>(initTransaction);
  const [userPetId, setUserPetId] = React.useState(-1);
  const { interval } = useIntervalContext();
  const { data, refetch } = useQuery({
    queryKey: ['checkPendingTransaction', userPetId, provider?._network],
    queryFn: () => DIAMONDRepository.checkPendingTransaction(provider, userPetId),
    retry: false,
    enabled: !!provider?.network && userPetId !== -1,
    refetchInterval: interval
  });
  const isPending = React.useMemo(() => {
    return !!data && data?.[0]?.[2] !== nonAddress;
  }, [data]);
  React.useEffect(() => {
    if (!data) {
      handleClose();
      return;
    }
    if (isPending) {
      const _transactionInfo = {
        commit: data?.[0]?.[2],
        nonce: data?.[0]?.[1],
        targetId: data?.[0]?.[0]?.toNumber(),
        type: data?.[1]
      };
      try {
        Sentry.captureMessage(`Retry Transaction with Pet ID ${userPetId} - ${JSON.stringify(_transactionInfo)}`);
      } catch (e) {
        Sentry.captureException(e);
      }
      setTransactionInfo(_transactionInfo, handleShow);
    } else {
      handleClose();
    }
  }, [data, handleShow, handleClose]);

  return (
    <RetryTransactionContext.Provider
      value={{
        isPending,
        isShow,
        handleClose,
        setUserPetId,
        transactionInfo,
        handleShow,
        refetch
      }}
    >
      {children}
    </RetryTransactionContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useRetryTransactionContext = (): IRetryTransactionContext => {
  const context = React.useContext(RetryTransactionContext);
  if (context === undefined) {
    throw new Error('useRetryTransactionContext Error');
  }
  return context as IRetryTransactionContext;
};
