import { BigNumber, ethers } from 'ethers';
import TOKENABI from '@/constants/abis/token.json';
import BUMPIESNFT from '@/constants/abis/bumpiesNft.json';
import { IToken } from '@/entities/token.interface';
import { ENVS } from '@/config';

export class Token {
  static async getTokenBalance(
    token: IToken,
    userAddr: string,
    provider: ethers.providers.JsonRpcProvider | undefined
  ): Promise<{
    balance?: BigNumber;
    decimals?: number;
  }> {
    if (!token.address || !userAddr)
      return {
        balance: undefined,
        decimals: 18
      };

    const NFTcontract = new ethers.Contract(token.address, TOKENABI, provider);

    const balance: BigNumber = await NFTcontract.balanceOf(userAddr);
    let decimals: number;
    if (!token?.decimals) {
      decimals = await NFTcontract.decimals();
    } else {
      decimals = token.decimals;
    }
    return {
      balance,
      decimals: decimals
    };
  }

  static async getNativeBalance(
    provider: ethers.providers.JsonRpcProvider | undefined,
    userAddr: string
  ): Promise<{
    balance?: BigNumber;
    decimals?: number;
  }> {
    if (!userAddr || !provider)
      return {
        balance: undefined,
        decimals: 18
      };

    const balance: BigNumber = await provider.getBalance(userAddr);
    return {
      balance,
      decimals: 18
    };
  }

  static async getMintETHFee(provider: ethers.providers.JsonRpcProvider | undefined): Promise<{
    rateWei: BigNumber;
    rateEther: string;
  }> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_NFTCONTRACT?.[0], BUMPIESNFT, provider);
    const rateWei: BigNumber = await NFTcontract.ETHrate();
    const rateEther = ethers.utils.formatEther(rateWei);
    return {
      rateWei,
      rateEther
    };
  }

  static async getApproval(
    provider: ethers.providers.JsonRpcProvider | undefined,
    to: string,
    account: string,
    spender: string,
    _decimals?: number
  ): Promise<{
    approval: BigNumber;
    decimals?: number;
  }> {
    if (!to || !account || !spender) throw new Error('Missing Input');

    const NFTcontract = new ethers.Contract(to, TOKENABI, provider);
    const approval: BigNumber = await NFTcontract.allowance(account, spender);
    let decimals: number;
    if (!_decimals) {
      decimals = await NFTcontract.decimals();
    } else {
      decimals = _decimals;
    }
    return {
      approval,
      decimals
    };
  }
}
