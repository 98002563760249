import React from 'react';
import { ENVS } from '@/config';
import Metamask from '@/assets/wallet/metamask.png';
import BBQIcon from '@/assets/wallet/bbq.png';
import ETHIcon from '@/assets/wallet/eth-rounded.png';
export const longInterval = 15000;
export const shortInterval = 3500;
export const ETHFee = '0.0001';
export const nonAddress = '0x0000000000000000000000000000000000000000000000000000000000000000';
export const address0 = '0x0000000000000000000000000000000000000000';
export const maxRetry = 5;
export const MinimumMintFee = ENVS.VITE_BASE_ENVIRONMENT[0] === 'production' ? '0.008' : '0.001';
export const SUPERBRIDGE_URL = 'https://superbridge.app/';
export const MEDIUM_ARTICLE_URL = 'https://medium.com/@bumpiesgg/navigating-base-your-in-depth-guide-to-obtaining-eth-on-the-base-network-0598b440d354';

export const cacheOptions = {
  gcTime: 24 * 60 * 60 * 1000,
  staleTime: 24 * 60 * 60 * 1000,
  meta: {
    persist: true
  }
};

export const lowerCacheOptions = {
  gcTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  meta: {
    persist: true
  },
  refetchOnMount: true
};

export const bumpiesConfig = {
  backgroundAlpha: 0,
  posX: 2,
  posY: 1.3,
  timeScale: 1,
  scaleRate: 500
};

export const inputAddressList = [
  <div className='labelsmall-bold label-white' key={0}>
    Manual Address Input
  </div>,
  <div className='flex items-center gap-4' key={1}>
    <img src={Metamask} alt='' className='w-6' />
    <span className='labelsmall-bold label-white'>Connect Metamask</span>
  </div>
];

export const selectTokenOptions = [
  <div className='flex items-center gap-2' key={0}>
    <img src={ETHIcon} alt='' className='w-6' />
    <span className='labelsmall-bold label-white'>ETH</span>
  </div>,
  <div className='flex items-center gap-2' key={1}>
    <img src={BBQIcon} alt='' className='w-6' />
    <span className='labelsmall-bold label-white'>BBQ</span>
  </div>
];

export const PVP_STRONGER_ODDS = 40;
export const PVP_WEAKER_ODDS = 60;
export const PVP_NORMAL_ODDS = 50;
export const PVP_FATIGUED_ODDS = 10;
