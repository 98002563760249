import React from 'react';
import { Import } from '@/utilities/import.utils';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { Loading } from '@/components/app-loading/app-loading';

const NotFoundRoute = Import({
  touch: React.lazy(() => import('../modules/not-found-route')),
  desktop: React.lazy(() => import('../modules/not-found-route'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const Login = Import({
  touch: React.lazy(() => import('../modules/login')),
  desktop: React.lazy(() => import('../modules/login'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const SubmitReferral = Import({
  touch: React.lazy(() => import('../modules/submit-referral')),
  desktop: React.lazy(() => import('../modules/submit-referral'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const NewsUpdate = Import({
  touch: React.lazy(() => import('../modules/news-update')),
  desktop: React.lazy(() => import('../modules/news-update'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const DemoPet = Import({
  touch: React.lazy(() => import('../modules/demo-pet')),
  desktop: React.lazy(() => import('../modules/demo-pet'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const DemoFight = Import({
  touch: React.lazy(() => import('../modules/demo-fight')),
  desktop: React.lazy(() => import('../modules/demo-fight'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const DemoPachinko = Import({
  touch: React.lazy(() => import('../modules/demo-pachinko')),
  desktop: React.lazy(() => import('../modules/demo-pachinko'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const Pet = Import({
  touch: React.lazy(() => import('../modules/pet')),
  desktop: React.lazy(() => import('../modules/pet'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const UserWallet = Import({
  touch: React.lazy(() => import('../modules/userwallet')),
  desktop: React.lazy(() => import('../modules/userwallet'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const UserAccount = Import({
  touch: React.lazy(() => import('../modules/user-account')),
  desktop: React.lazy(() => import('../modules/user-account'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const Leaderboard = Import({
  touch: React.lazy(() => import('../modules/leaderboard')),
  desktop: React.lazy(() => import('../modules/leaderboard'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const SlotMachine = Import({
  touch: React.lazy(() => import('../modules/pachinko')),
  desktop: React.lazy(() => import('../modules/pachinko'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const HotdogRoll = Import({
  touch: React.lazy(() => import('../modules/hotdog-roll')),
  desktop: React.lazy(() => import('../modules/hotdog-roll'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const GoogleLoginSuccess = Import({
  touch: React.lazy(() => import('../modules/google-success')),
  desktop: React.lazy(() => import('../modules/google-success'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const elements = [
  {
    Component: Pet,
    UnLogged: Login,
    path: '/'
  },
  {
    Component: UserWallet,
    UnLogged: Login,
    path: '/deposit-eth'
  },
  {
    Component: SubmitReferral,
    UnLogged: Login,
    path: '/submit-referral'
  },
  {
    Component: NewsUpdate,
    UnLogged: Login,
    path: '/news-update'
  },
  {
    Component: DemoPet,
    UnLogged: Login,
    path: '/demo-pet'
  },
  {
    Component: DemoFight,
    UnLogged: Login,
    path: '/demo-fight'
  },
  {
    Component: DemoPachinko,
    UnLogged: Login,
    path: '/demo-pachinko'
  },
  {
    Component: UserWallet,
    UnLogged: Login,
    path: '/user-wallet'
  },
  {
    Component: Pet,
    UnLogged: Login,
    path: '/pet'
  },
  {
    Component: UserAccount,
    UnLogged: Login,
    path: '/account'
  },
  {
    Component: Leaderboard,
    UnLogged: Login,
    path: '/fight'
  },
  {
    Component: SlotMachine,
    UnLogged: Login,
    path: '/pachinko'
  },
  {
    Component: HotdogRoll,
    UnLogged: Login,
    path: '/hotdog-roll'
  },
  {
    Component: GoogleLoginSuccess,
    path: 'oauth/google/success',
    UnLogged: GoogleLoginSuccess
  },
  {
    Component: NotFoundRoute,
    path: '*',
    UnLogged: NotFoundRoute
  }
];

export const RouterProvider = () => {
  const token = useSelector(selectToken);
  return (
    <BrowserRouter>
      <Routes>
        {elements.map(({ Component, UnLogged, path }, idx) => (
          <Route
            key={idx}
            element={<React.Suspense fallback={<Loading />}>{token ? <Component /> : <UnLogged />}</React.Suspense>}
            path={path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
