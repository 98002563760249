import camelCase from 'lodash/camelCase';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const camelCaseKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCaseKeys(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelCaseKeys(obj[key])
      }),
      {}
    );
  }
  return obj;
};

export const addressSplit = (addr: string) => {
  return addr?.substr(0, 11) + '...' + addr?.substr(addr?.length - 10, addr?.length);
};

export const getBumpieStatus = (value: any) => {
  // Less or equal to 9 Hours but more than 3 hours life time remaining >> Unwell
  const now = new Date().getTime();
  const timeUntilStarvingInMs = Number(value || '0') * 1000;
  const distanceTimeInMs = timeUntilStarvingInMs - now;
  const hoursRemaining = Math.floor(distanceTimeInMs / (1000 * 60 * 60));
  if (hoursRemaining > 16) return 'Happy';
  if (hoursRemaining > 9) return 'Meh';
  if (hoursRemaining > 3) return 'Unwell';
  if (hoursRemaining > 0) return 'Dying';
  return 'Dead';
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
