import { cacheOptions } from '@/constants/app.contanst';
import { ITokenBalance } from '@/entities/token.interface';
import * as React from 'react';
import { useIntervalContext } from './intervalContext';
import { useContractProviderContext } from './contractProviderContext';
import { useQuery } from '@tanstack/react-query';
import { Token as TokenRepository } from '@/repository/token.repository';
import { Profiles as ProfileRepository } from '@/repository/profile.repository';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { ENVS } from '@/config';
import { IReferred } from '@/entities/profile.interface';

export interface IAccountInfoContext {
  userWallet: string;
  nativeBalance?: ITokenBalance;
  tokenBalance?: ITokenBalance;
  isReferred?: {
    data: IReferred;
  };
}

const AccountInfoContext = React.createContext<IAccountInfoContext | null>(null);
export const AccountInfoContextProvider = ({ children }: React.PropsWithChildren) => {
  const { interval } = useIntervalContext();
  const { provider } = useContractProviderContext();

  const token = useSelector(selectToken);
  const { data: userProfile } = useQuery({
    queryKey: ['retrieveProfile', token],
    queryFn: () => ProfileRepository.getProfile(),
    retry: false,
    enabled: !!token,
    ...cacheOptions
  });

  const userWallet = React.useMemo(() => {
    return userProfile?.user?.attributes?.bumpiesUserWallets?.[0]?.wallet_address || '';
  }, [userProfile]);

  const { data: isReferred } = useQuery({
    queryKey: ['getReferred', token],
    queryFn: () => ProfileRepository.getReferredWithoutToken(),
    enabled: !!token,
    ...cacheOptions
  });
  const { data: nativeBalance } = useQuery({
    queryKey: ['getNativeBalance', userWallet, provider?._network],
    queryFn: () => TokenRepository.getNativeBalance(provider, userWallet || ''),
    retry: false,
    retryDelay: 3000,
    refetchInterval: interval,
    enabled: !!userWallet && !!provider?._network,
    gcTime: 0
  });
  const { data: tokenBalance } = useQuery({
    queryKey: ['getTokenBalance', userWallet, provider?._network],
    queryFn: () =>
      TokenRepository.getTokenBalance(
        {
          address: ENVS.VITE_BASE_GAMETOKEN?.[0]
        },
        userWallet || '',
        provider
      ),
    retry: false,
    retryDelay: 3000,
    refetchInterval: interval,
    enabled: !!userWallet && !!provider?._network,
    gcTime: 0
  });
  const value = {
    userWallet,
    tokenBalance,
    nativeBalance,
    isReferred
  };

  return <AccountInfoContext.Provider value={value}>{children}</AccountInfoContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAccountInfoContext = (): IAccountInfoContext => {
  const context = React.useContext(AccountInfoContext);
  if (context === undefined) {
    throw new Error('useAccountInfoContext Error');
  }
  return context as IAccountInfoContext;
};
