import {
  IProfile,
  ISendEmailCode,
  IVerifyEmailCode,
  IUserReferralCode,
  ISubmitReferralCode,
  IUserWallet,
  IReferred,
  IReferralAddress
} from '@/entities/profile.interface';
import http from '@/utilities/http.utils';

export class Profiles {
  static async getProfile(): Promise<IProfile> {
    const response: IProfile = await http.get(`api/v1/profile/info`);
    return response;
  }

  static async getReferred(token?: string): Promise<{
    data: IReferred;
  }> {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const response = await http.get(`api/v1/referral/referred`, {
      headers
    });
    return response;
  }

  static async getReferredWithoutToken(): Promise<{
    data: IReferred;
  }> {
    const response = await http.get(`api/v1/referral/referred`);
    return response;
  }

  static async getAddressFormRefCode(code: string): Promise<IReferralAddress> {
    const response: any = await http.get(`api/v1/webhook/referral-address?code=${code}`);
    return response;
  }

  static async getUserWalletAccount(): Promise<IUserWallet> {
    const response: IUserWallet = await http.get(`api/v1/wallet/address`);
    return response;
  }

  static async getUserReferralCode(): Promise<IUserReferralCode> {
    const response: IUserReferralCode = await http.get(`api/v1/referral/mine`);
    return response;
  }
  static async retrieveWallet(): Promise<string> {
    const response: string = await http.get(`api/v1/wallet/address`);
    return response;
  }

  static async exportWallet(): Promise<IUserWallet> {
    const response: IUserWallet = await http.get(`api/v1/profile/export-wallet`);
    return response;
  }

  static async signTx(rawTransaction: any): Promise<{ response: IUserWallet | unknown; success: boolean }> {
    try {
      const response: IUserWallet = await http.post(`/api/v1/wallet/sign`, rawTransaction);
      return {
        response,
        success: true
      };
    } catch (error) {
      return {
        success: false,
        response: error
      };
    }
  }

  static async submitUserReferralCode(
    code: string
  ): Promise<{ response: ISubmitReferralCode | unknown; success: boolean }> {
    try {
      if (!code) {
        throw new Error('Missing Code');
      }
      const response: ISubmitReferralCode = await http.post(`api/v1/referral/register`, {
        referralCode: code
      });
      return {
        response,
        success: true
      };
    } catch (error) {
      return {
        success: false,
        response: error
      };
    }
  }

  static async refreshUserReferralCode(): Promise<{ response: IUserReferralCode | unknown; success: boolean }> {
    try {
      const response: IUserReferralCode = await http.post(`api/v1/referral/create`);
      return {
        response,
        success: true
      };
    } catch (error) {
      return {
        success: false,
        response: error
      };
    }
  }

  static async sendEmailCode(email: string): Promise<{ response: ISendEmailCode | any; success: boolean }> {
    if (!email) {
      throw new Error('Missing Email');
    }
    try {
      const response: ISendEmailCode = await http.post(`api/v1/auth/mail-request`, { email });
      return {
        response,
        success: true
      };
    } catch (error) {
      return {
        success: false,
        response: error
      };
    }
  }

  static async verifyEmailCode(
    email: string,
    code: string
  ): Promise<{ response: IVerifyEmailCode | unknown; success: boolean }> {
    if (!email) {
      throw new Error('Missing Email');
    }
    if (!code) {
      throw new Error('Missing Code');
    }
    try {
      const response: IVerifyEmailCode = await http.post(`api/v1/auth/mail-confirm`, {
        email,
        code
      });
      return {
        response,
        success: true
      };
    } catch (error) {
      return {
        success: false,
        response: error
      };
    }
  }
}
