import { BigNumber, ethers } from 'ethers';
import GETTERFACETABI from '@/constants/abis/getterFace.json';
import BUMPIESNFT from '@/constants/abis/bumpiesNft.json';
import { ENVS } from '@/config';

// let provider = new ethers.providers.JsonRpcProvider(rpcUrls[parseInt(ENVS.VITE_BASE_CHAIN_ID?.[0] || '10')]?.[0]);
export class DIAMOND {
  static async getPetStatus(petId: number, provider?: ethers.providers.JsonRpcProvider): Promise<any> {
    if (!petId) return -1;

    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const status: any = await NFTcontract.getPetInfo(petId);
    return status;
  }

  static async getPetRewardDebt(petId: number, provider?: ethers.providers.JsonRpcProvider): Promise<any> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const rewardDebt: any = await NFTcontract.petRewardDebt(petId);
    return rewardDebt;
  }

  static async getPetETHOwned(petId: number, provider?: ethers.providers.JsonRpcProvider): Promise<any> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const rewardDebt: any = await NFTcontract.ethOwed(petId);
    return rewardDebt;
  }

  static async getPetStatusByIds(petIds: number[] = [], provider?: ethers.providers.JsonRpcProvider): Promise<any> {
    if (petIds?.length === 0) return -1;

    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const status: any = await NFTcontract.getPetsInfo(petIds);
    return status;
  }

  static async getPachikoPointsReward(
    randomNumber: number,
    provider?: ethers.providers.JsonRpcProvider
  ): Promise<string> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const reward: string = await NFTcontract.getPachikoPointsReward(randomNumber);
    return reward;
  }

  static async getAllConsumable(petId: number, provider?: ethers.providers.JsonRpcProvider): Promise<any> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const items: any = await NFTcontract.getConsumablesByPetId(petId);
    return items;
  }

  static async getActivatePrice(provider?: ethers.providers.JsonRpcProvider): Promise<BigNumber> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const price: BigNumber = await NFTcontract.getActivatePrice();
    return price;
  }

  static async checkProviderStatus(
    provider: ethers.providers.JsonRpcProvider | undefined,
    changeProvider: () => void
  ): Promise<boolean> {
    try {
      const blockNumber = await provider?.getBlockNumber();
      if (blockNumber) {
        return true;
      }
      changeProvider();
      return false;
    } catch {
      changeProvider();
      return false;
    }
  }

  static async checkPendingTransaction(
    provider: ethers.providers.JsonRpcProvider | undefined,
    petId: number
  ): Promise<any> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const price: any = await NFTcontract.getPendingRequest(petId);
    return price;
  }

  static async checkIsNonceUsed(
    provider: ethers.providers.JsonRpcProvider | undefined,
    _nonce: string
  ): Promise<boolean> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const status: boolean = await NFTcontract.isNonceUsed(_nonce);
    return status;
  }

  static async isClaimedFreeConsumable(
    provider: ethers.providers.JsonRpcProvider | undefined,
    owner: string
  ): Promise<boolean> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_DINAMOND?.[0], GETTERFACETABI, provider);
    const status: boolean = await NFTcontract.isClaimedFreeConsumable(owner);
    return status;
  }

  static async isFreeMinted(address: string, provider?: ethers.providers.JsonRpcProvider): Promise<boolean> {
    const NFTcontract = new ethers.Contract(ENVS.VITE_BASE_NFTCONTRACT?.[0], BUMPIESNFT, provider);
    const isMinted: boolean = await NFTcontract.hasMintedFreeToken(address);
    return isMinted;
  }
}
