const isMobile = window.matchMedia('(pointer: coarse) and (hover: none)').matches;

export function Import({
  touch,
  desktop
}: {
  touch: React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
  desktop: React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
}) {
  return isMobile ? touch : desktop;
}
