import { longInterval, shortInterval } from '@/constants/app.contanst';
import * as React from 'react';

export interface IIntervalContext {
  isSendingTransaction: number;
  setIsSendingTransaction: (isSending: number) => void;
  interval: number | false;
  setInterval: (interval: number | false) => void;
}

const IntervalContext = React.createContext<IIntervalContext | null>(null);
export const IntervalContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isSendingTransaction, setIsSendingTransaction] = React.useState<number>(-1);
  const [interval, setInterval] = React.useState<number | false>(longInterval);
  const value = React.useMemo(() => {
    return {
      isSendingTransaction,
      setIsSendingTransaction: (isSending: number) => {
        setIsSendingTransaction(isSending);
      },
      interval,
      setInterval
    };
  }, [setIsSendingTransaction, isSendingTransaction, interval]);
  React.useEffect(() => {
    let timer: any;
    if (isSendingTransaction === -1) {
      setInterval(longInterval);
    } else {
      setInterval(shortInterval);
      timer = setTimeout(() => {
        setIsSendingTransaction(-1);
      }, isSendingTransaction);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isSendingTransaction]);

  return <IntervalContext.Provider value={value}>{children}</IntervalContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useIntervalContext = (): IIntervalContext => {
  const context = React.useContext(IntervalContext);
  if (context === undefined) {
    throw new Error('useIntervalContext Error');
  }
  return context as IIntervalContext;
};
