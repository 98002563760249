import React, { memo } from 'react';
import { Wrapper } from './app-loading.styled';
import { DotLottiePlayer } from '@dotlottie/react-player';

const LoadingComponent = () => {
  return (
    <Wrapper id='loading'>
      <DotLottiePlayer src='/lottie-source/loading.lottie' autoplay loop className='!w-[250px]'></DotLottiePlayer>
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);
