import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/app';
import './index.css';
import '@fontsource/anton';
import '@fontsource/dm-sans';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/400-italic.css';
import { PostHogProvider } from 'posthog-js/react';
import { ENVS } from '@/config';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1d30191b8e5356656c1ce74243c3eb5a@o4506064694214656.ingest.us.sentry.io/4507145497149440',

  beforeSend(event) {
    if (event.exception?.values?.[0]) {
      const error = event.exception?.values?.[0];

      //Reference Errors
      if (error.type === 'ReferenceError') {
        //Ignore touch event error
        if (error.value === 'TouchEvent is not defined') {
          return null;
        }
      }

      //Type Errors
      if (error.type === 'TypeError') {
        //Ignore Zaraz errors
        if (error.stacktrace?.frames?.[0].function?.includes('kL.zaraz.init')) {
          return null;
        }
      }
    }
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    new Sentry.BrowserProfilingIntegration(),
    Sentry.replayIntegration()
  ],
  environment: ENVS.VITE_BASE_ENVIRONMENT[0],
  // Performance Monitoring
  tracesSampleRate: 0.8, //  Capture 100% of the transactions
  profilesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api(-dev)?\.bumpies\.gg/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const options = {
  //api_host: ENVS.VITE_BASE_POSTHOG_HOST[0],
};

const rootElement = document.querySelector('[data-js="root"]');

if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <PostHogProvider apiKey={ENVS.VITE_BASE_POSTHOG_KEY[0]} options={options}>
      <App />
    </PostHogProvider>
  </StrictMode>
);
