/* eslint-disable react-refresh/only-export-components */
import React, { useMemo } from 'react';
import { ITheme, ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle, css } from 'styled-components';
import { Colors } from './theme.styled';
import { FONT_SIZES } from './theme.fonts';

export const MEDIA_WIDTHS = {
  upToMobileSmall: 410,
  upToExtraSmall: 640,
  upToSmall: 768,
  upToMedium: 1056,
  upToLarge: 1460,
  uptoVeryLarge: 1600
};

export const FONT_WEIGHT = {
  fontRegular: 400,
  fontBold: 700
};
export const FONT_FAMILY = {
  primaryFont: 'DM Sans',
  secondaryFont: 'Anton',
  tertiaryFont: 'Impact'
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;
const white = 'rgba(255, 255, 255, 1)';
const black = '#000000';
export function colors(): Colors {
  return {
    white,
    black,
    successColor: 'rgba(45, 255, 239, 1)',
    errorColor: 'rgba(255, 59, 59, 1)',
    lostColor: 'rgba(255, 135, 135, 1)',
    darkBlack: 'rgba(9, 6, 44, 1)',
    lightBlack: 'rgba(6, 3, 15, 1)',
    borderColor: 'rgba(60, 118, 151, 1)',
    bumpiesGrey: 'rgba(130, 128, 148, 1)',
    lightCard: 'rgba(38, 45, 77, 1)',
    bumpiesPink: 'rgba(239, 81, 255, 1)',
    shadowColor: 'rgba(29, 25, 74, 1)',
    bumpiesGreen: 'rgba(0, 255, 117, 1)',
    bumpiesYellow: 'rgba(252, 248, 59, 1)',
    bumpiesOrange: 'rgba(221, 158, 107, 1)',
    bumpiesSilver: 'rgba(189, 208, 225, 1)'
  };
}

export function appTheme(): ITheme {
  return {
    ...colors(),

    mediaWidth: mediaWidthTemplates,
    // font_weight
    fontWeight: FONT_WEIGHT,
    // font_family
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZES,
    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  };
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const themeObject = useMemo(() => appTheme(), []);

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
};

export const ThemedGlobalStyle = createGlobalStyle`
  * {
        box-sizing: border-box !important;
        font-family: ${FONT_FAMILY.primaryFont};
        font-size: ${FONT_SIZES.regular};
        font-weight: ${FONT_WEIGHT.fontRegular};
        color: ${colors()?.white};
  }
   ::-webkit-scrollbar {
      width: 4px;
      background: #000;
  }

    ::-webkit-scrollbar-thumb {
        // background: #393812;
        background: linear-gradient(238.93deg, #90caffd6 18.8%, #07ff889e 81.2%);
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
  :root{
    --toastify-color-light: #121212;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #121212;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: "Ubuntu Mono";
    --toastify-z-index: 9999;

    --toastify-text-color-light: #fff;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }
  :root:root {
    --adm-color-success: #00b578;
    --adm-color-warning: #ff8f1f;
    --adm-color-danger: #ff3141;

    --adm-color-white: #ffffff;
    --adm-color-default: ${colors()?.white};
    --adm-color-primary: ${colors()?.darkBlack};
    --adm-color-weak: none;
    --adm-color-light: none;
    --adm-color-border: none;
    --adm-color-box: none;
    --adm-button-background-color: transparent;
    --adm-color-primary: ${colors()?.successColor};
    --adm-button-background-color: ${colors()?.darkBlack};
    --adm-font-size-main: var(--adm-font-size-5);
    --adm-button-border-radius: 52px;
    --adm-color-border: ${colors()?.borderColor};
    --adm-center-popup-max-width: 100vw;
    --adm-color-background: transparent;
  }   
  html,body {
        box-sizing: border-box !important;
        margin: 0;
        overflow: hidden;
     
  }
  body{
    height: 100%;
    position: fixed;
    width:100%;
    
  }
  .app-container{
    width: 100%;
    max-width: 530px;
  }
  .primary-button{
    span{
      color: ${colors()?.darkBlack};
      font-family: ${FONT_FAMILY.secondaryFont};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      }
    .adm-loading {
      svg, rect{
        fill: ${colors()?.darkBlack} ;
      }
   
    }
    .adm-button-loading-wrapper{
      color: ${colors()?.darkBlack} ;
      font-family: ${FONT_FAMILY.secondaryFont};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .secondary-button{
    span{
      color: ${colors()?.white};
      font-family: ${FONT_FAMILY.secondaryFont};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      }
  }
  .adm-button{
    padding: 14px !important;
  }
  .app-big-title{
    font-family: ${FONT_FAMILY.secondaryFont};
    font-size: ${FONT_SIZES.avgLarge};
    font-weight: ${FONT_WEIGHT.fontRegular}
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors()?.white};
  }
  .app-small-title{
    font-family: 'Anton';
    font-size:  ${FONT_SIZES.large};
    font-weight: ${FONT_WEIGHT.fontRegular};
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: ${colors()?.white};
  }
  .app-subtitle{
    font-weight: ${FONT_WEIGHT.fontRegular};
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: ${colors()?.white};

  }
  .text-button{
    font-weight: ${FONT_WEIGHT.fontBold};;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: ${colors()?.successColor};
    cursor: pointer;
  }
  .labelsmall-light{
    font-size: ${FONT_SIZES.small};
    font-weight:${FONT_WEIGHT.fontRegular};
  }
  .labelregular-light{
    font-size: ${FONT_SIZES.regular};
    font-weight:${FONT_WEIGHT.fontRegular};
  }
  .labelsupersmall-light{
    font-size: ${FONT_SIZES.superSmall};
    font-weight:${FONT_WEIGHT.fontRegular};
  }
  .labelsmall-bold{
    font-size: ${FONT_SIZES.small};
    font-weight:${FONT_WEIGHT.fontBold};
  }
  .labelregular-bold{
    font-size: ${FONT_SIZES.regular};
    font-weight:${FONT_WEIGHT.fontBold};
  }
  .labelmedium-bold{
    font-size: ${FONT_SIZES.medium};
    font-weight:${FONT_WEIGHT.fontBold};
  }
  .labelsupermedium-bold{
    font-size: ${FONT_SIZES.superMedium};
    font-weight:${FONT_WEIGHT.fontBold};
  }
  .label-white{
    color: ${colors()?.white};
  }
  .label-grey{
    color: ${colors()?.bumpiesGrey};
  }
  .label-success{
    color: ${colors()?.successColor};
  }
  .label-error{
    color: ${colors()?.errorColor};
  }
  .label-lost{
    color: ${colors()?.lostColor};
  }
  .label-secondary{
    font-family: ${FONT_FAMILY.secondaryFont}
  }
  .primary-modal-wrapper{
    .adm-center-popup-wrap{
        top:0 !important;
        transform: translate(-50%, 0) !important;
    }
  }
  .primary-modal-wrapper{
    z-index: 99 !important;
  }
  .primary-modal{
    width: 100vw !important;
    max-width: 530px;
    height: 100svh;
    max-height:unset !important;
    padding: 0 !important;
    background:transparent !important ;
    border-radius: 0px !important;
    .adm-modal-content{
      padding: 0 !important;
      height: 100svh;
      max-height:unset !important;
    }
  }
  .secondary-modal-wrapper{
    z-index: 101 !important;
    .adm-center-popup-mask{
      backdrop-filter: blur(7px);
    }
  }
  .secondary-modal{
    width: fit-content !important;
    height: fit-content;
    max-height:unset !important;
    padding: 0 !important;
    background:transparent !important ;
    border-radius: 0px !important;
   
    .adm-modal-content{
      padding: 0 !important;
      height: fit-content;
      max-height:unset !important;
    }
  }
  .primary-dropdown{
    background: transparent !important;
  }
  .adm-popup.adm-dropdown-popup{
    max-width: 530px;
    left: 50%;
    transform: translateX(-50%);
  }
  .adm-popup-body.adm-dropdown-popup-body.adm-popup-body-position-top{
    height: 90%;
    >div{
      height: 100%;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .app-input{
    width: 100%;
    padding: 14px 20px;
    border-radius: 52px;
    border: 1px solid rgba(38, 45, 77, 1);
    gap: 16px;
    span{
      color: ${colors()?.white};
    }
    input {
          width: 100%;
          font-size: ${(props) => props.theme.fontSize.medium};
          font-weight: ${(props) => props.theme.fontWeight.fontRegular};
          letter-spacing: 0em;
          text-align: left;
          color: ${(props) => props.theme.white};
        }
    
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          text-align: start;
        }
        input {
          text-align: start;
          background-color: transparent;
          width: 10px;
          font-size: var(--font-lg);
          border: none;
          outline: none;
          padding: 10px;
          width: 100%;
          color: ${(props) => props.theme.color2};
          font-size: 14px;
          padding: 2px;
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: #FFF;
          }
        }

       ${(props) => props.theme.mediaWidth.upToMobileSmall`
            padding: 14px 14px;
            border-radius: 24px;
            gap: 12px;
          `}
  
  }
`;

export const dialogStyle = {
  background: 'linear-gradient(0deg, #09062C, #09062C)',
  padding: '32px 20px',
  borderRadius: '32px',
  border: '2px solid rgba(38, 45, 77, 1)',
  minWidth: '320px'
};
